/*
 * PARALLAX
 */
$('.jarallax').jarallax();
$('.jarallax-keep-img').jarallax({
    keepImg: true,
});

/*
 * LEAFLET INIT
 */
if (document.querySelector('#map') !== null) {
    var map = L.map('map');
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);
    var fontAwesomeIcon = L.divIcon({
        html: '<i class="fad fa-map-marker-alt fa-4x"></i>',
        iconSize: [20, 20],
        className: 'auerDivIcon'
    });
}

/*
 *LEAFLET SHOW 
 */
$('.nav-open').on('click', function (e) {
    $('#myNav').css('height', '100%');
    map.setView([$(this).data('lat'), $(this).data('lon')], 13);
    L.marker([$(this).data('lat'), $(this).data('lon')], { icon: fontAwesomeIcon }).addTo(map)
        .bindPopup(Base64.decode($(this).data('company')))
        .openPopup();
});
$('.nav-close').on('click', function (e) {
    $('#myNav').css('height', '0%');
});

/*
 * BOOTSTRAP
 */
$('[data-toggle="tooltip"]').tooltip();

/*
 * CART SHOW
 */
$('#dropdownShoppingcart').hover(function (e) {
    let routes = JSON.parse(Base64.decode($('meta[name="cart.routes"]').attr('content')));
    let el = $(this);
    el.off('hover');
    //console.log("in3");console.log(e.type);
    $.get(routes.show, function (d) {
        if (d.success) {
            $('[aria-labelledby="dropdownShoppingcart"]').html(Base64.decode(d.result));
        }
    });
}, function (e) {
    //console.log("out3");console.log(e.type)
});

/* 
 * CART ADD 
 */
$('.cart-add').on('click', function (e) {
    let btnVal = $(this).val();
    let partElement = $('#participant-' + btnVal);
    let course = JSON.parse(Base64.decode($('meta[name="course.messages"]').attr('content')));
    let routes = JSON.parse(Base64.decode($('meta[name="cart.routes"]').attr('content')));
    //console.log( partElement.prop('tagName')); //INPUT,SELECT
    //console.log( partElement.val()); //none, Array
    //console.log( typeof(partElement.val())); //string,object

    if (typeof (partElement.val()) === 'object' && Object.keys(partElement.val()).length == 0) {
        /*
        $.showModal({
            title: "<h3>{{ __('course.modal_participant_title') }}</h3>",
            body: "{{ __('course.modal_participant_body') }}",
            modalDialogClass: "modal-dialog-centered"
        });
        $('.modal-header').css('background-color', '#FFF3CD');
        */
        Swal.fire({
            title: course.modal_participant_title,
            text: course.modal_participant_body,
            icon: 'error',
        })
        return;
    }

    $.post(routes.add, {
        "_token": $('meta[name="csrf-token"]').attr('content'),
        'event': btnVal,
        'participants': partElement.val()
    })
        .done(function (d) {
            //console.log(d);
            if (d.success) {
                Swal.fire({
                    title: course.modal_participant_title,
                    text: d.result.message,
                    icon: 'success',
                })
                $('.cart-basket').html(d.result.qty);
            } else {
                Swal.fire({
                    title: course.modal_participant_title,
                    text: d.result.message,
                    icon: 'error',
                })
            }
        });
});

/* 
 * CART ADD FILTER
 */
$('.cart-add-filter').on('click', function (e) {
    let btnVal = $(this).val();
    let participants = JSON.parse(Base64.decode($('#cart-data').data('participants')));
    let partAvailable = $('#cart-data').data('status');
    let course = JSON.parse(Base64.decode($('meta[name="course.messages"]').attr('content')));
    let routes = JSON.parse(Base64.decode($('meta[name="cart.routes"]').attr('content')));

    async function checkParticipants(validCustomer) {
        let partSubmit = null;
        if (validCustomer) {
            partSubmit = await Swal.fire({
                title: course.selectparticipant,
                input: 'select',
                inputOptions: participants,
                inputPlaceholder: course.modal_select,
                showCancelButton: true,
                inputAttributes: {
                    required: true
                },
                validationMessage: course.validation,
            }).then(function (result) {
                if (result.isConfirmed) {
                    return new Promise((resolve) => {
                        resolve(result.value)
                    });
                }
                return Promise.resolve(null);
            });
        } else {
            partSubmit = await Promise.resolve('none');
        }
        return partSubmit;
    }
    checkParticipants(partAvailable)
        .then((data) => {
            //console.log(data)
            if (data !== null) {
                $.post(routes.add, {
                    "_token": $('meta[name="csrf-token"]').attr('content'),
                    'event': btnVal,
                    'participants': data
                }).done(function (d) {
                    //console.log(d);
                    if (d.success) {
                        Swal.fire({
                            title: course.modal_participant_title,
                            text: d.result.message,
                            icon: 'success',
                        })
                        $('.cart-basket').html(d.result.qty);
                    } else {
                        Swal.fire({
                            title: course.modal_participant_title,
                            text: d.result.message,
                            icon: 'error',
                        })
                    }
                });
            }
        });
});

/* 
 * CART DEL 
 */
$('[aria-labelledby="dropdownShoppingcart"]').on('click', '.cart-del', function (e) {
    let btnVal = $(this).val();
    let btnName = $(this).attr('name').split('-')[2];
    let course = JSON.parse(Base64.decode($('meta[name="course.messages"]').attr('content')));
    let routes = JSON.parse(Base64.decode($('meta[name="cart.routes"]').attr('content')));

    Swal.fire({
        title: course.modal_event_del_title,
        html: course.modal_event_del_body + "<br /><i>" + course.modal_text1 +
            ":</i> " +
            $('.cart-evt-' + btnName).html() + "<br /><i>" + course.modal_text2 +
            ":</i> " + $(
                '.cart-qty-' + btnName).html(),
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: course.modal_yes,
        denyButtonText: course.modal_no,
    }).then((result) => {
        if (result.isConfirmed) {
            $.post(routes.del, {
                "_token": $('meta[name="csrf-token"]').attr('content'),
                'event': btnVal
            })
                .done(function (d) {
                    //console.log(d);
                    if (d.success) {
                        $('.cart-basket').html(d.result.qty);
                        Swal.fire(course.modal_deleted, '', 'success');
                    }
                });
        } else if (result.isDenied) {
            Swal.fire(course.modal_cancel, '', 'info')
        }
    })
});

/* 
 * BASKET ORDER 
 */
/*
$('#form').validator().on('submit', function(e) {
    if (e.isDefaultPrevented()) {
        console.log('fehler');
    } else {
        console.log('ok')
    }
})
*/

/* 
 * FILTER CHANGE TAB 
 */
$('button[data-toggle="tab"]').on('shown.bs.tab', function (e) {
    auerShuffleGrid.filter(Shuffle.ALL_ITEMS);
    auerShuffleList.filter(Shuffle.ALL_ITEMS);
})
